<template>
  <a-layout>
    <div class="nav">
      <Breadcrumb>
        <BreadcrumbItem v-if="type === 'detail'">订单查看</BreadcrumbItem>
        <BreadcrumbItem v-else>订单审核</BreadcrumbItem>
      </Breadcrumb>
    </div>
    <section class="card__container">
      <div class="container__title">基本信息</div>
      <a-form class="advanced-search-form">
        <a-row>
          <a-col :span="8">
            <a-form-item label="订单号">
              <div>
                {{ info.orderSn }}
              </div>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="下单机构">
              <div>{{ info.orderCorporationName }}</div>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="买方">
              <div v-if="info.orderTypeValue === '租赁联合运营'">
                电行世界
              </div>
              <div v-else>{{ info.purchaserCorporationName }}</div>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="8">
            <a-form-item label="卖方">
              <div>{{ info.producerCorporationName || "-" }}</div>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="订单类型">
              {{ info.orderTypeValue }}
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="下单时间">
              {{ tsFormat(info.createTime) }}
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="8">
            <a-form-item label="出厂价总额">
              {{ info.factoryTotalPrice }} 元
            </a-form-item>
          </a-col>
          <a-col :span="8" v-if="info.orderTypeValue !== '租赁联合运营'">
            <a-form-item
              :label="
                info.purchaserCorporationDistributorTypeValue === '货栈'
                  ? '平台价总额'
                  : '进货价总额'
              "
            >
              {{ info.totalPrice }} 元
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="交付周期">
              {{ info.orderCycle || " - " }}
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item
              label="订单状态"
              v-if="
                fromRouterName !== 'OperationPurchaseOrderList' &&
                  storeRouterName !== 'OperationPurchaseOrderList'
              "
            >
              <span
                v-if="info.orderStatusValue === '未通过'"
                class="status--red"
              >
                {{ info.orderStatusValue }}
              </span>
              <span v-else>{{ info.orderStatusValue }}</span>
            </a-form-item>
            <a-form-item label="订单状态" v-else>
              {{ info.nodeStatus }}
            </a-form-item>
          </a-col>
          <a-col :span="8" v-if="info.orderTypeValue === '远期订单'">
            <a-form-item label="下定月份">
              <div>{{ info.installmentSn }}</div>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="订单备注">
              <div>{{ info.orderMemo || " - " }}</div>
            </a-form-item>
          </a-col>
          <a-col :span="8" v-if="info.orderTypeValue == '租赁联合运营'">
            <a-form-item label="分润比例">
              {{ info.operationShareRatio || "-" }}%
            </a-form-item>
          </a-col>
          <a-col :span="8" v-if="info.orderTypeValue == '租赁联合运营'">
            <a-form-item label="投资占比">
              {{ info.operationInvestRatio || "-" }}%
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </section>
    <section class="card__container">
      <div class="container__title">收货信息</div>
      <a-form>
        <a-row>
          <a-col :span="8">
            <a-form-item label="收货人姓名">
              <div>{{ info.consigneeRealName }}</div>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="收货人电话">
              <div>{{ info.consigneeTel }}</div>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="收货地址">
              <div>{{ info.consigneeAddress }}</div>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </section>
    <section class="card__container">
      <div
        class="container__title between"
        v-if="info.productTypeValue === '整车'"
      >
        <div>商品信息（整车）</div>
      </div>
      <div class="container__title" v-else>商品信息（配件）</div>
      <div v-if="info.orderTypeValue == '租赁联合运营'">
        <a-table
          class="commodity__table"
          :columns="commodityColumnsProcure"
          :data-source="instantOrderSkus"
          :loading="loading"
          :pagination="false"
          :rowKey="(record, index) => index"
        >
          <template slot="skuDepositTotalPrice" slot-scope="text, scope">
            {{ scope.skuDepositTotalPrice + scope.goodsTotalPayment }}
          </template>
        </a-table>
      </div>
      <div v-else>
        <a-table
          class="commodity__table"
          :columns="commodityColumns"
          :data-source="instantOrderSkus"
          :loading="loading"
          :pagination="false"
          :rowKey="(record, index) => index"
        >
          <template slot="title" v-if="info.orderTypeValue === '远期订单'">
            {{ info.installmentSn }}
          </template>
          <template slot="price">
            {{
              info.purchaserCorporationDistributorTypeValue === "货栈"
                ? "货栈采购单价（元）"
                : "进货单价（元）"
            }}
          </template>
          <template slot="totalPrice">
            {{
              info.purchaserCorporationDistributorTypeValue === "货栈"
                ? "货栈采购总价（元）"
                : "进货总价（元）"
            }}
          </template>
        </a-table>
      </div>
    </section>
    <section class="card__container">
      <div class="container__title">付款流水</div>
      <a-table
        class="flow__table"
        :columns="flowColumns"
        :data-source="flowList"
        :loading="flowLoading"
        :pagination="false"
        :rowKey="(record, index) => index"
      >
        <template slot="huifuSeqId" slot-scope="text">
          {{ text || "-" }}
        </template>
        <template slot="paymentTime" slot-scope="text">
          {{ text ? tsFormat(text) : "-" }}
        </template>
      </a-table>
    </section>
    <section class="card__container">
      <div class="container__title">合同信息</div>
      <a-table
        class="agreement__table"
        :columns="agreementColumns"
        :data-source="agreementData"
        :loading="loading"
        :pagination="false"
        :rowKey="(record, index) => index"
      >
        <template slot="signTime" slot-scope="text">
          {{ text ? tsFormat(text) : "-" }}
        </template>
        <template slot="action" slot-scope="text, record">
          <a
            :href="imageBaseUrl + record.contractFile.fileUrl"
            v-if="record.contractFile"
            target="_blank"
          >
            预览
          </a>
        </template>
      </a-table>
    </section>
    <section class="card__container" v-if="saleBillNum > 0">
      <div class="container__title">
        物流信息
        <span v-if="saleBillNum > 0">（{{ saleBillNum }}）条</span>
        <a
          class="check__all"
          v-if="saleBillNum > 1"
          @click="onCheckDeliverDetail"
        >
          查看全部
        </a>
      </div>
      <div class="sale__title">第一批明细：</div>
      <a-form class="advanced-search-form">
        <a-row>
          <a-col :span="8">
            <a-form-item label="发货时间">
              {{
                saleBillInfo.logisticsSendTime
                  ? tsFormat(saleBillInfo.logisticsSendTime)
                  : "-"
              }}
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="商品金额">
              {{ calculatePrice(inventoryData) }} 元
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="物流公司">
              {{
                saleBillInfo.logisticsName ? saleBillInfo.logisticsName : "-"
              }}
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="8">
            <a-form-item label="物流单号">
              {{ saleBillInfo.logisticsSn ? saleBillInfo.logisticsSn : "-" }}
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="物流电话">
              {{ saleBillInfo.logisticsTel ? saleBillInfo.logisticsTel : "-" }}
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="24">
            <a-form-item label="发货清单">
              <a-table
                :columns="inventoryColumns"
                :data-source="inventoryData"
                :loading="inventoryLoading"
                :pagination="false"
                :rowKey="(record, index) => index"
              />
            </a-form-item>
          </a-col>
        </a-row>
        <div v-if="receiveBill && receiveBill.length > 0">
          <div v-for="item in receiveBill" :key="item.receivingId">
            <a-row>
              <a-col :span="8">
                <a-form-item label="收货时间">
                  {{ item.signTime ? tsFormat(item.signTime) : "-" }}
                </a-form-item>
              </a-col>
              <a-col :span="8">
                <a-form-item label="发票信息">
                  <a>查看</a>
                </a-form-item>
              </a-col>
              <a-col :span="8">
                <a-form-item label="商品金额">
                  {{ calculatePrice(item.deliverVoList) }}
                </a-form-item>
              </a-col>
            </a-row>
            <a-row>
              <a-col :span="24">
                <a-form-item label="收货清单">
                  <a-table
                    :columns="inventoryColumns"
                    :data-source="item.deliverVoList"
                    :pagination="false"
                    :rowKey="(record, index) => index"
                  />
                </a-form-item>
              </a-col>
            </a-row>
          </div>
        </div>
      </a-form>
    </section>
    <section class="card__container" :class="type === 'examine'">
      <div class="container__title between">
        订单流程日志
        <a @click="onShowProcessImg" class="process_img">点击查看流程图</a>
        <div v-viewer class="images">
          <img
            v-if="info.purchaserCorporationDistributorTypeValue === '渠道终端'"
            src="../../assets/order/img_zd_order.png"
            :data-source="require('../../assets/order/img_zd_order.png')"
            alt="流程图"
          />
          <img
            v-else
            src="../../assets/order/img_service_order.png"
            :data-source="require('../../assets/order/img_service_order.png')"
            alt="流程图"
          />
        </div>
      </div>
      <a-table
        class="log__table"
        :columns="logColumns"
        :data-source="logData"
        :loading="logLoading"
        :pagination="false"
        :rowKey="(record, index) => index"
      >
        <template slot="createTime" slot-scope="text">
          {{ tsFormat(text) }}
        </template>
        <template slot="reason" slot-scope="text">
          {{ text || " - " }}
        </template>
      </a-table>
    </section>
    <section
      class="card__container"
      :class="
        type === 'examine' ||
        (type === 'detail' && info.orderTypeValue === '远期订单')
          ? 'bottom'
          : 'low__bottom'
      "
    >
      <div class="container__title between">
        <div class="log__title">
          付款流程日志
          <a-button
            type="primary"
            class="btn--reload"
            @click="onReload"
            v-if="
              info.orderStatusValue === '未通过' &&
                personnelType.includes(info.lastApprovalPersonnelType)
            "
          >
            重新发起审核
          </a-button>
        </div>
        <a @click="onShowPayProcessImg" class="process_img">点击查看流程图</a>
        <div v-viewer class="pay_images">
          <img
            src="../../assets/imgs/financial/process.png"
            :data-source="require('../../assets/imgs/financial/process.png')"
            alt="流程图"
          />
        </div>
      </div>
      <a-table
        class="log__table"
        :columns="payLogColumns"
        :data-source="payLogData"
        :loading="payLogLoading"
        :pagination="false"
        :rowKey="(record, index) => index"
      >
        <template slot="operatorTime" slot-scope="text">
          {{ tsFormat(text) }}
        </template>
        <template slot="orderApprovalMemo" slot-scope="text">
          {{ text || " - " }}
        </template>
        <template slot="paymentApprovalMemo" slot-scope="text">
          {{ text || " - " }}
        </template>
      </a-table>
    </section>
    <section class="examine__container" v-if="type === 'examine'">
      <a-form class="examine__form">
        <a-row>
          <a-col :span="12">
            <a-form-item label="订单审核意见">
              <a-textarea
                v-model="approvalMemo"
                :auto-size="{ minRows: 1, maxRows: 2 }"
              />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="付款审核意见">
              <a-textarea
                v-model="paymentApprovalMemo"
                :auto-size="{ minRows: 1, maxRows: 2 }"
              />
            </a-form-item>
          </a-col>
        </a-row>
        <div class="operation">
          <div class="tip">
            <a-icon type="info-circle" />
            审核不通过，两个审核意见必填；付款流程结束后，订单审核意见将会显示到订单流程日志意见列表
          </div>
          <a-space>
            <a-button
              @click="onCheckRelevanceOrder"
              v-if="info.orderTypeValue === '远期订单'"
            >
              查看关联订单
            </a-button>
            <a-button @click="onUnPass">不通过</a-button>
            <a-button type="primary" @click="onPass">通过</a-button>
          </a-space>
        </div>
      </a-form>
    </section>
    <section
      class="examine__container"
      v-if="type === 'detail' && info.orderTypeValue === '远期订单'"
    >
      <div class="operation--right">
        <a-button @click="onCheckRelevanceOrder" type="primary">
          查看关联订单
        </a-button>
      </div>
    </section>
    <div class="top__back__container" v-if="showTop" @click="onScrollTop">
      <img src="../../assets/order/icon-top.png" alt="icon" />
      回到顶部
    </div>

    <a-modal
      :visible="payModal"
      title="确认付款金额"
      width="40%"
      @cancel="onCancelModal"
      @ok="onConfirmPay"
      :confirm-loading="confirmLoading"
    >
      <div class="pay__num">
        实付¥
        <div v-if="info.instantBill">
          {{ info.instantBill.factoryActualPayment }}
        </div>
      </div>
      <div class="total__num">
        订单金额：¥
        <div v-if="info.instantBill">
          {{ info.instantBill.factoryTotalPrice }}
        </div>
      </div>
      <a-divider style="margin-bottom: 12px" />
      <div class="pay__item">
        <div class="pay__item--logo">
          <img src="../../assets/order/pay.png" />
          余额支付
        </div>
        <a-radio-group :default-value="1">
          <a-radio :value="1" />
        </a-radio-group>
      </div>
      <a-divider style="margin-top: 12px" />
    </a-modal>

    <!--  支付结果弹窗  -->
    <a-modal
      :visible="payResultModal"
      width="50%"
      title="支付结果"
      @cancel="onConfirmPayResult"
    >
      <template slot="footer">
        <a-button type="primary" @click="onConfirmPayResult">
          <span v-if="payResult">确认（{{ second }}s）</span>
          <span v-else>确认</span>
        </a-button>
      </template>
      <div class="logo__container">
        <img src="../../assets/order/icon_success.png" v-if="payResult" />
        <img src="../../assets/order/icon_fail.png" v-if="!payResult" />
        <span v-if="payResult">支付成功</span>
        <span v-if="!payResult">支付失败</span>
      </div>
      <a-divider />
      <a-form>
        <a-row>
          <a-col :span="12">
            <a-form-item label="交易时间">
              {{ payInfo.paymentTime ? tsFormat(payInfo.paymentTime) : "-" }}
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="交易单号">
              <div style="word-break: break-word">{{ payInfo.billId }}</div>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="12">
            <a-form-item label="订单金额">
              ¥{{ payInfo.paymentAmount }}
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="实付款">
              ¥{{ payInfo.actualPayment }}
            </a-form-item>
          </a-col>
        </a-row>
        <a-row v-if="!payResult">
          <a-col :span="24">
            <a-form-item label="失败原因">
              {{ errorMsg }}
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </a-modal>
  </a-layout>
</template>

<script>
import Breadcrumb from "@/components/Breadcrumb";
import BreadcrumbItem from "@/components/BreadcrumbItem";
import { tsFormat } from "@/components/DateUtils";
import {
  fetchOrderDetail,
  fetchOrderLog,
  fetchPayLogList,
  fetchPayResult,
  ordersOfflinePaymentPost,
  payExamine,
  reissueExamine
} from "@/services/OrderManageService";
import { imageBaseUrl } from "@/services/HttpService";
import {
  fetchReceiveBillBySaleBill,
  fetchSaleBillByOrderSn
} from "@/services/OrderService";

const commodityColumns = [
  {
    title: "商品编码",
    dataIndex: "skuCode",
    width: "20%"
  },
  {
    title: "商品名称",
    dataIndex: "skuName",
    width: "30%"
  },
  {
    title: "出厂单价（元）",
    dataIndex: "skuFactoryPrice",
    width: "10%"
  },
  {
    scopedSlots: {
      title: "price"
    },
    dataIndex: "skuPurchasePrice",
    width: "10%"
  },
  {
    title: "采购数量",
    dataIndex: "purchaseQuantity",
    width: "10%"
  },
  {
    title: "出厂总价（元）",
    dataIndex: "skuFactoryTotalPrice",
    width: "10%"
  },
  {
    scopedSlots: {
      title: "totalPrice"
    },
    dataIndex: "skuPurchaseTotalPrice",
    width: "10%"
  }
];
const commodityColumnsProcure = [
  {
    title: "商品编码",
    dataIndex: "skuCode",
    width: "20%"
  },
  {
    title: "商品名称",
    dataIndex: "skuName",
    width: "30%"
  },
  {
    title: "出厂单价（元）",
    dataIndex: "skuFactoryPrice",
    width: "10%"
  },
  {
    title: "采购数量",
    dataIndex: "purchaseQuantity",
    width: "10%"
  },
  {
    title: "保证金单价（元）",
    dataIndex: "skuDepositPrice",
    width: "15%"
  },
  {
    title: "货款总额（元）",
    dataIndex: "goodsTotalPayment",
    width: "10%"
  },
  {
    title: "支付总额（元）",
    dataIndex: "skuDepositTotalPrice",
    scopedSlots: { customRender: "skuDepositTotalPrice" },
    width: "15%"
  }
];
const logColumns = [
  {
    title: "执行人",
    dataIndex: "realName",
    width: "15%"
  },
  {
    title: "机构名称",
    dataIndex: "corporationName",
    width: "15%"
  },
  {
    title: "渠道类型",
    dataIndex: "distributorType",
    width: "15%"
  },
  {
    title: "操作时间",
    dataIndex: "createTime",
    scopedSlots: { customRender: "createTime" },
    width: "20%"
  },
  {
    title: "执行动作",
    dataIndex: "operation",
    width: "5%"
  },
  {
    title: "审核意见",
    dataIndex: "reason",
    scopedSlots: { customRender: "reason" },
    width: "30%"
  }
];
const payLogColumns = [
  {
    title: "执行人",
    dataIndex: "operatorName",
    width: "10%"
  },
  {
    title: "部门名称",
    dataIndex: "personnelTypeName",
    width: "10%"
  },
  {
    title: "操作时间",
    dataIndex: "operatorTime",
    scopedSlots: { customRender: "operatorTime" },
    width: "20%"
  },
  {
    title: "执行动作",
    dataIndex: "operation",
    width: "10%"
  },
  {
    title: "付款审核意见",
    dataIndex: "paymentApprovalMemo",
    scopedSlots: { customRender: "paymentApprovalMemo" },
    width: "25%"
  },
  {
    title: "订单审核意见",
    dataIndex: "orderApprovalMemo",
    scopedSlots: { customRender: "orderApprovalMemo" },
    width: "25%"
  }
];
const agreementColumns = [
  {
    title: "签署方1",
    dataIndex: "partyFirst",
    width: "20%"
  },
  {
    title: "签署方2",
    dataIndex: "partySecond",
    width: "20%"
  },
  {
    title: "合同名称",
    dataIndex: "contractName",
    width: "30%"
  },
  {
    title: "签订时间",
    dataIndex: "signTime",
    scopedSlots: { customRender: "signTime" },
    width: "20%"
  },
  {
    title: "操作",
    dataIndex: "action",
    scopedSlots: { customRender: "action" },
    width: "10%"
  }
];
const inventoryColumns = [
  {
    title: "商品名称",
    dataIndex: "skuName",
    width: "40%"
  },
  {
    title: "商品编号",
    dataIndex: "skuCode",
    width: "15%"
  },
  {
    title: "商品序列号",
    dataIndex: "goodsSn",
    width: "15%"
  },
  {
    title: "商品单价（元）",
    dataIndex: "skuFactoryPrice",
    width: "15%"
  },
  {
    title: "商品数量",
    dataIndex: "quantity",
    width: "15%"
  }
];
const flowColumns = [
  {
    title: "流水号",
    dataIndex: "huifuSeqId",
    scopedSlots: { customRender: "huifuSeqId" },
    width: "15%"
  },
  {
    title: "付款机构",
    dataIndex: "paymentCorporationName",
    width: "20%"
  },
  {
    title: "收款机构",
    dataIndex: "receiveCorporationName",
    width: "20%"
  },
  {
    title: "应付款金额（元）",
    dataIndex: "actualPayment",
    width: "10%"
  },
  // {
  //   title: "实际付款金额（元）",
  //   dataIndex: "accountPaid",
  //   width: "10%"
  // },
  {
    title: "付款时间",
    dataIndex: "paymentTime",
    scopedSlots: { customRender: "paymentTime" },
    width: "15%"
  },
  {
    title: "状态",
    dataIndex: "paymentStatus",
    width: "10%"
  }
];

export default {
  name: "PayExamineDetail",
  components: { Breadcrumb, BreadcrumbItem },
  data() {
    return {
      commodityColumns,
      commodityColumnsProcure,
      loading: false,
      logColumns,
      logLoading: false,
      logData: [],
      payLogColumns,
      payLogLoading: false,
      payLogData: [],
      agreementColumns,
      agreementData: [],
      orderSn: this.$route.params.orderSn,
      type: this.$route.params.type,
      info: {},
      tsFormat,
      approvalMemo: "",
      paymentApprovalMemo: "",
      instantOrderSkus: [],
      forwardOrderSkus: [],
      showTop: false,
      bills: [],
      personnelType: [],
      imageBaseUrl,
      payModal: false,
      payResultModal: false,
      payResult: false,
      second: 5,
      confirmLoading: false,
      payInfo: {},
      errorMsg: "",
      fromRouterName: "",
      storeRouterName: "",
      saleBillInfo: {},
      saleBillNum: 0,
      inventoryColumns,
      inventoryData: [],
      inventoryLoading: false,
      receiveBill: [],
      flowColumns,
      flowList: [],
      flowLoading: false
    };
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.fromRouterName = from.name;
      if (vm.fromRouterName) {
        sessionStorage.setItem("fromRouterName", vm.fromRouterName);
      }
    });
  },
  beforeRouteLeave(to, from, next) {
    sessionStorage.removeItem("fromRouterName");
    next();
  },
  watch: {
    second: {
      handler(newVal) {
        if (newVal === 0) {
          this.$router.push({ name: "PayExamine" });
        }
      }
    }
  },
  mounted() {
    if (sessionStorage.getItem("fromRouterName")) {
      this.storeRouterName = sessionStorage.getItem("fromRouterName");
    }
    if (localStorage.getItem("personnelType")) {
      this.personnelType = JSON.parse(localStorage.getItem("personnelType"));
    }
    this.loadDetail();
    this.loadLogList();
    this.loadPayLogList();
    this.loadSaleBill();
    this.loadPayBill();
    const that = this;
    window.onscroll = function() {
      const scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      const windowHeight =
        document.documentElement.clientHeight || document.body.clientHeight;
      const scrollHeight =
        document.documentElement.scrollHeight || document.body.scrollHeight;
      if (scrollTop + windowHeight >= scrollHeight) {
        that.showTop = true;
      } else {
        that.showTop = false;
      }
    };
  },
  methods: {
    // 加载详情
    loadDetail() {
      this.loading = true;
      const params = {
        orderSn: this.orderSn,
        type: "payment-approval"
      };
      fetchOrderDetail(params).then(resp => {
        this.loading = false;
        if (resp.data.code === "SUCCESS") {
          this.info = resp.data.data;
          if (this.info.orderTypeValue === "租赁联合运营") {
            this.instantOrderSkus = this.info.instantOrderSkus;
          } else {
            this.instantOrderSkus = this.calculate(this.info.instantOrderSkus);
          }

          const params = {
            value: this.info.instantBill
          };
          this.bills = [params];
        }
        this.agreementData = this.info.contracts;
      });
    },
    // 加载流程日志
    loadLogList() {
      this.logLoading = true;
      fetchOrderLog(this.orderSn, "").then(resp => {
        this.logLoading = false;
        if (resp.data.code === "SUCCESS") {
          this.logData = resp.data.data;
        }
      });
    },
    // 加载付款流程日志
    loadPayLogList() {
      this.payLogLoading = true;
      fetchPayLogList(this.orderSn).then(resp => {
        this.payLogLoading = false;
        if (resp.data.code === "SUCCESS") {
          this.payLogData = resp.data.data;
          if (this.payLogData.length > 0) {
            const item = this.payLogData[this.payLogData.length - 1];
            this.approvalMemo = item.orderApprovalMemo;
          }
        }
      });
    },
    // 转化下定月份
    transferOrderMonth(obj) {
      let str = "";
      let list = [];
      for (const key in obj) {
        list.push(key);
      }
      if (list.length > 0) {
        str = list.join(",");
      } else {
        str = " - ";
      }
      return str;
    },
    // 计算
    calculate(list) {
      let arr = [...list];
      if (list && list.length > 0) {
        let totalPrice = 0;
        let num = 0;
        let allPrice = 0;
        let skuDepositTotalPrice = 0;
        list.forEach(item => {
          num = num + parseInt(item.purchaseQuantity);
          totalPrice = totalPrice + parseFloat(item.skuFactoryTotalPrice);
          allPrice = allPrice + parseFloat(item.skuPurchaseTotalPrice);
          skuDepositTotalPrice =
            skuDepositTotalPrice + parseFloat(item.skuDepositTotalPrice);
        });
        const params = {
          skuCode: "合计：",
          purchaseQuantity: num,
          skuFactoryTotalPrice: totalPrice,
          skuPurchaseTotalPrice: allPrice,
          skuDepositTotalPrice: skuDepositTotalPrice
        };
        arr.push(params);
      }
      return arr;
    },
    // 回到顶部
    onScrollTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    },
    // 查看流程图
    onShowProcessImg() {
      const viewer = this.$el.querySelector(".images").$viewer;
      viewer.show();
    },
    // 查看付款流程图
    onShowPayProcessImg() {
      const viewer = this.$el.querySelector(".pay_images").$viewer;
      viewer.show();
    },
    // 不通过
    onUnPass() {
      if (!this.approvalMemo) {
        this.$message.info("请输入订单审核意见");
        return;
      }
      if (!this.paymentApprovalMemo) {
        this.$message.info("请输入付款审核意见");
        return;
      }
      const params = {
        orderSn: this.orderSn,
        isAdopt: false,
        approvalMemo: this.approvalMemo,
        paymentApprovalMemo: this.paymentApprovalMemo
      };
      payExamine(params).then(resp => {
        if (resp.data.code === "SUCCESS") {
          this.$message.success("提交成功");
          if (this.storeRouterName === "ToDoTask") {
            this.$router.replace({
              name: "PayOrderDetail",
              params: { orderSn: this.orderSn, type: "detail" }
            });
          } else {
            this.$router.push({ name: "PayExamine" });
          }
        }
      });
    },
    // 通过
    onPass() {
      // if (this.personnelType !== "accounting_second_dept") {
      //
      // } else {
      //   this.payModal = true;
      // }
      const params = {
        orderSn: this.orderSn,
        isAdopt: true,
        approvalMemo: this.approvalMemo,
        paymentApprovalMemo: this.paymentApprovalMemo
      };
      payExamine(params).then(resp => {
        if (resp.data.code === "SUCCESS") {
          if (
            JSON.parse(localStorage.getItem("personnelType")).indexOf(
              "OperationHeadquartersAccountingSecondDept"
            ) !== -1
          ) {
            ordersOfflinePaymentPost({
              orderSn: params.orderSn,
              type: "teller"
            }).then(res => {
              if (res.data.code === "SUCCESS") {
                // if (res.data.data) {
                //   this.$message.success("提交成功");
                //   this.$router.push({
                //     name: "CashierDetail",
                //     query: {
                //       type: "pay",
                //       rn: res.data.data,
                //       relId: params.orderSn
                //     }
                //   });
                // } else {
                //   if (this.storeRouterName === "ToDoTask") {
                //     this.$router.replace({
                //       name: "PayOrderDetail",
                //       params: { orderSn: this.orderSn, type: "detail" }
                //     });
                //   } else {
                //     this.$router.push({ name: "PayExamine" });
                //   }
                // }
                if (this.storeRouterName === "ToDoTask") {
                  this.$router.replace({
                    name: "PayOrderDetail",
                    params: { orderSn: this.orderSn, type: "detail" }
                  });
                } else {
                  this.$router.push({ name: "PayExamine" });
                }
              }
            });
          } else {
            if (this.storeRouterName === "ToDoTask") {
              this.$router.replace({
                name: "PayOrderDetail",
                params: { orderSn: this.orderSn, type: "detail" }
              });
            } else {
              this.$router.push({ name: "PayExamine" });
            }
          }
        }
      });
    },
    // 重新发起审核
    onReload() {
      reissueExamine(this.orderSn).then(resp => {
        if (resp.data.code === "SUCCESS") {
          this.$message.success("重新发起成功");
          if (this.storeRouterName === "ToDoTask") {
            this.$router.push({ name: "ToDoTask" });
          } else {
            this.$router.push({ name: "PayExamine" });
          }
        }
      });
    },
    // 取消打开支付弹窗
    onCancelModal() {
      this.payModal = false;
    },
    // 确认支付弹窗
    onConfirmPay() {
      this.confirmLoading = true;
      const params = {
        orderSn: this.orderSn,
        isAdopt: true,
        approvalMemo: this.approvalMemo,
        paymentApprovalMemo: this.paymentApprovalMemo
      };
      payExamine(params)
        .then(resp => {
          this.confirmLoading = false;
          if (resp.data.code === "SUCCESS") {
            this.payModal = false;
            this.payResult = true;
            this.payResultModal = true;
            this.loadPayDetail();
          } else {
            this.payModal = false;
            this.payResult = false;
            this.payResultModal = true;
            this.loadPayDetail();
            this.errorMsg = resp.data.errorMsg;
          }
        })
        .catch(() => {
          this.confirmLoading = false;
        });
    },
    // 加载支付详情
    loadPayDetail() {
      fetchPayResult(this.orderSn).then(resp => {
        if (resp.data.code === "SUCCESS") {
          const data = resp.data.data;
          if (data && data.length > 0) {
            let item = data.find(
              ele =>
                ele.paymentCorporationId ===
                localStorage.getItem("corporationId")
            );
            this.payInfo = item;
          }
          if (this.payResult) {
            let timer = setInterval(() => {
              this.second--;
            }, 1000);
            this.$once("hook:beforeDestroy", () => {
              clearInterval(timer);
              timer = null;
            });
          }
        }
      });
    },
    // 确认支付结果
    onConfirmPayResult() {
      this.$router.push({ name: "PayExamine" });
    },
    // 查看关联订单
    onCheckRelevanceOrder() {
      this.$router.push({ name: "RelevanceOrder" });
    },
    // 获取发货单
    loadSaleBill() {
      this.inventoryLoading = true;
      fetchSaleBillByOrderSn(this.orderSn)
        .then(resp => {
          this.inventoryLoading = false;
          if (resp.data.code === "SUCCESS") {
            const data = resp.data.data;
            if (data && data.length > 0) {
              let list = data;
              this.saleBillNum = list.length;
              this.saleBillInfo = list[0];
              this.inventoryData = this.saleBillInfo.detailVoList;
              this.loadReceiveBill(this.saleBillInfo.consignmentId);
            }
          }
        })
        .catch(() => {
          this.inventoryLoading = false;
        });
    },
    // 获取收货单
    loadReceiveBill(consignmentId) {
      fetchReceiveBillBySaleBill(consignmentId).then(resp => {
        if (resp.data.code === "SUCCESS") {
          this.receiveBill = resp.data.data;
        }
      });
    },
    // 计算商品金额
    calculatePrice(list) {
      let num = 0;
      if (list && list.length > 0) {
        list.forEach(item => {
          num = num + item.skuFactoryPrice * item.quantity;
        });
        num = num.toFixed(2);
      } else {
        num = "-";
      }
      return num;
    },
    // 查看全部物流
    onCheckDeliverDetail() {
      this.$router.push({
        name: "DeliverDetail",
        params: { orderSn: this.orderSn }
      });
    },
    // 查付款单
    loadPayBill() {
      this.flowLoading = true;
      fetchPayResult(this.orderSn)
        .then(resp => {
          this.flowLoading = false;
          if (resp.data.code === "SUCCESS") {
            const data = resp.data.data;
            if (data && data.length > 0) {
              this.flowList = data;
            }
          }
        })
        .catch(() => {
          this.flowLoading = false;
        });
    }
  }
};
</script>

<style scoped>
.container__title {
  color: rgba(16, 16, 16, 1);
  font-size: 14px;
  font-weight: bold;
}

/deep/ .ant-form-item {
  margin: 10px 40px 0 0;
}

/deep/ .ant-form-item .ant-form-item-label {
  min-width: 100px !important;
  text-align: left !important;
}

/deep/ .ant-form-item-label > label {
  color: rgba(102, 102, 102, 0.8);
  font-size: 14px;
}

.between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.standard__container {
  display: flex;
  align-items: flex-end;
}

.input__standard {
  width: 100px;
  margin-right: 10px;
}

.standard__word {
  min-width: 120px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 10px;
}

.top {
  margin-top: 20px;
}

.commodity__table,
.log__table {
  margin-top: 20px;
}

.bottom {
  margin-bottom: 140px;
}

.examine__container {
  background-color: white;
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  padding: 20px 10px 20px 220px;
  z-index: 1;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.19);
}

.examine__form /deep/ .ant-form-item {
  display: flex;
  margin: 0 40px 0 0;
}

.operation {
  margin-top: 20px;
  margin-right: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.operation--right {
  margin-right: 40px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.tip {
  display: flex;
  align-items: center;
  color: rgba(153, 153, 153, 1);
  font-size: 12px;
}

.tip /deep/ .anticon {
  margin-right: 6px;
}

/deep/ .ant-form-item {
  display: flex;
}

/deep/ .ant-table-title {
  background-color: rgba(235, 240, 255, 1);
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
}

.top__back__container {
  border-radius: 4px 0px 0px 4px;
  background-color: rgba(16, 16, 16, 0.4);
  color: white;
  box-sizing: border-box;
  padding: 8px 10px 8px 15px;
  position: fixed;
  right: 0;
  bottom: 200px;
  z-index: 10000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  cursor: pointer;
}

.top__back__container img {
  width: 24px;
  height: 24px;
}

.price__item {
  width: 50%;
  border-radius: 4px;
  background-color: transparent;
  border: 1px solid rgba(164, 173, 179, 0.8);
  box-sizing: border-box;
  padding: 13px 10px;
  margin-top: 20px;
}

.price__item--left {
  border-right: 1px solid rgba(227, 227, 227, 1);
}

.price__item--value {
  text-align: left;
  display: flex;
  justify-content: space-between;
}

.right {
  margin-right: 30%;
}

.left {
  margin-left: 30%;
}

.right__bottom {
  margin-right: 30%;
  margin-bottom: 10px;
}

.left__bottom {
  margin-left: 30%;
  margin-bottom: 10px;
}

.price__item--label {
  min-width: 85px;
}

.between {
  display: flex;
  justify-content: space-between;
}

.images img {
  display: none;
}

.pay_images img {
  display: none;
}

.process_img {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  font-weight: unset;
}

.low__bottom {
  margin-bottom: 30px;
}

.agreement__table {
  margin-top: 20px;
}

.status--red {
  color: #ff5757;
}

.log__title {
  display: flex;
  align-items: center;
}

.btn--reload {
  margin-left: 50px;
}

.pay__num {
  display: flex;
  color: rgba(255, 0, 0, 1);
  font-size: 14px;
  font-weight: bold;
  align-items: flex-end;
  justify-content: center;
}

.pay__num div {
  font-size: 28px;
  transform: translateY(6px);
}

.total__num {
  display: flex;
  justify-content: center;
  color: rgba(16, 16, 16, 1);
  font-weight: 500;
  margin-top: 15px;
}

.pay__item {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.pay__item--logo {
  display: flex;
  align-items: center;
}

.pay__item--logo img {
  width: 38px;
  height: 38px;
  margin-right: 5px;
}

.logo__container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.logo__container img {
  width: 60px;
  height: 60px;
  margin-right: 20px;
}

.logo__container span {
  color: rgba(102, 102, 102, 1);
  font-size: 28px;
  font-weight: 500;
}

.sale__title {
  margin-top: 1rem;
}

.top {
  margin-top: 20px;
}

.flow__table {
  margin-top: 20px;
}
</style>
